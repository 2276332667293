/* @tailwind base; */
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
}

img {
  max-width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #57c47a 0%, #a8e492 100%);
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*  this is for react-zoom-pan-pinch */
.react-transform-wrapper {
  overflow: visible !important;
}

#toast-container-premium {
  top: 70px !important;
  right: 20px !important;
  position: fixed;
}
